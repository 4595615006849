// This is where you override default Bootstrap variables
// 1. All Bootstrap variables are here => https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
// 2. These variables are defined with default value (see https://robots.thoughtbot.com/sass-default)
// 3. You can override them below!

// General style
$font-family-sans-serif: $body-font;
$headings-font-family: $headers-font;
$body-bg: $dark-green;
$font-size-base: 1rem;

// Colors
$body-color: $dark-green;
$see-through-body-color: rgba(38, 70, 83, 0.75);

$primary: $orange;
$secondary: $green;
$background-color: $dark-green;
// $success: $green;
// $info: $yellow;
// $danger: $red;
// $warning: $red;

// Buttons & inputs' radius
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;


// Spacing
$spacer: 5px;

// Effects
$text-dropshadow: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
$box-dropshadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
// Override other variables below!

