// Define variables for your color scheme

// For example:
$red: #FD1015;
$blue: #167FFB;
$yellow: #FFC65A;
$orange: #FF6F1E;
$green: #2A9D8F;
$gray: #0E0000;
$white: white;
$light-gray: #F4F4F4;
$purple: #30194D;
$dark-purple: #211134;
$dark-green: #264653;
$black: black;
