.social-bar {
  position: fixed;

  right: 5vw;
  bottom: 0px;
}

.line {
  height: 20vh;
  width: 2px;
  padding: 0;
  margin: 0;
  border: 1px solid $secondary;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.icon {
  color: $secondary;
  font-size: x-large;
  &:hover {
    color: $primary;
  }
}
