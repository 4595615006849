.section {
  margin-top: 10%;
  scroll-margin-top: 225px;
}

@media (min-width: 768px) {
  .section {
    scroll-margin-top: 100px;
  }
}

.list-group-item {
  background-color: transparent;
  border: none;
  padding: 0;
  list-style-type: circle;
}
