.my-btn-primary {
  border: 2px solid $primary;
  box-sizing: border-box;
  border-radius: 11px;
  background-color: transparent;
  color: $primary;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:hover {
    background-color: $primary;
    color: $white;
    border: 2px solid $primary;
  }
}
