// Graphical variables
@import 'bootstrap/dist/css/bootstrap.css';
@import "config/colors";
@import "config/fonts";
@import "config/bootstrap_variables";
@import "font-awesome/css/font-awesome.min.css";

// Your CSS partials
@import "components/button";
@import "components/navbar";
@import "components/section";
@import "components/social_side_bar";
@import "components/spinner_logo";
@import "components/custom_card";
@import "components/footer";
@import "components/first_section";


* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.App {
  height: 100vh;
}

#root {
  height: 100vh;
}

body {
  background-color: $body-bg;
  margin: 0;
  font-family: 'Nova Square', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-shadow {
  filter: $text-dropshadow;
}

.text-color-primary {
  color: $primary;
}

h1 {
  color: $white;
  font-size: calc(1.375rem + 2.5vw);
}

h2 {
  color: $secondary;
  font-size: calc(1.325rem + 2vw);
}

h3 {
  color: $primary;
}

h4 {
  color: $primary;
}

h5 {
  color: $white;
  margin: 0;
}

p {
  color: $secondary;
  }

hr {
  border: 1px solid $primary;
  margin: 0 0 10px 0;
  width: 35%;
  background-color: $primary;
}

a {
  text-decoration: none;
}
