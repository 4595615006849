@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");

// Define fonts for body and headers
$body-font: "Nova Square", cursive;
$headers-font: "Nova Square", cursive;

// To use a font file (.woff) uncomment following lines
// @font-face {
//   font-family: "Font Name";
//   src: font-url('FontFile.eot');
//   src: font-url('FontFile.eot?#iefix') format('embedded-opentype'),
//        font-url('FontFile.woff') format('woff'),
//        font-url('FontFile.ttf') format('truetype')
// }
// $my-font: "Font Name";

* {
  font: "Nova Square", cursive;
}


