@import 'bootstrap/dist/css/bootstrap.css';
@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");
@import "font-awesome/css/font-awesome.min.css";
* {
  font: "Nova Square", cursive;
}

.my-btn-primary {
  border: 2px solid #FF6F1E;
  box-sizing: border-box;
  border-radius: 11px;
  background-color: transparent;
  color: #FF6F1E;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.my-btn-primary:hover {
  background-color: #FF6F1E;
  color: white;
  border: 2px solid #FF6F1E;
}

.navbar {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  background-color: rgba(38, 70, 83, 0.75);
}

.navbar-brand {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.nav-link {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: #FF6F1E;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
  text-decoration: none;
}
.nav-link:hover {
  color: #2A9D8F;
}

.nav-item {
  margin-right: 35px;
  margin-left: 35px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.navbar-toggler {
  color: #FF6F1E;
}
.navbar-toggler span {
  display: none;
}

.section {
  margin-top: 10%;
  scroll-margin-top: 225px;
}

@media (min-width: 768px) {
  .section {
    scroll-margin-top: 100px;
  }
}
.list-group-item {
  background-color: transparent;
  border: none;
  padding: 0;
  list-style-type: circle;
}

.social-bar {
  position: fixed;
  right: 5vw;
  bottom: 0px;
}

.line {
  height: 20vh;
  width: 2px;
  padding: 0;
  margin: 0;
  border: 1px solid #2A9D8F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.icon {
  color: #2A9D8F;
  font-size: x-large;
}
.icon:hover {
  color: #FF6F1E;
}

.spinner-logo {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.custom-card {
  position: relative;
  display: flex;
  max-width: 80%;
  background-color: transparent;
  transition: max-width 0.25s;
  min-height: 300px;
}
.custom-card:hover {
  max-width: 85%;
}

.card-img {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
}

.card-title {
  color: white;
  position: absolute;
  font-size: medium;
  right: 0;
  top: -25px;
  padding: 0;
  margin: 0;
}

.card-text-box {
  position: absolute;
  background: rgba(38, 70, 83, 0.75);
  transform: translate(-50%, -50%);
  font-size: small;
  width: 60%;
  height: auto;
  max-width: 300px;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
  border-top: solid 1.5px #FF6F1E;
  top: 50%;
}

.card-icon {
  color: #FF6F1E;
  font-size: x-large;
  padding-bottom: 3%;
}
.card-icon:hover {
  color: #2A9D8F;
}

@media (max-width: 950px) {
  .custom-card {
    max-width: 90%;
  }
  .custom-card:hover {
    max-width: 95%;
  }
}
@media (max-width: 768px) {
  .custom-card {
    background-color: transparent;
    background-image: url("/public/assets/images/spotify-logo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100px;
    max-width: 100%;
  }
  .custom-card:hover {
    max-width: 100%;
  }

  .card-img {
    display: none;
  }

  .card-text-box {
    position: initial;
    background: rgba(38, 70, 83, 0.75);
    font-size: small;
    width: 100%;
    height: 100%;
    max-width: 100%;
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
    border-top: solid 1.5px #FF6F1E;
    transform: none;
    top: 0;
  }
}
.footer-repo-link {
  text-decoration: none;
  font-size: smaller;
  color: #2A9D8F;
}
.footer-repo-link:hover {
  color: #FF6F1E;
}

.first-section {
  margin-top: 0;
  height: 85vh !important;
  scroll-margin-top: 100px;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.App {
  height: 100vh;
}

#root {
  height: 100vh;
}

body {
  background-color: #264653;
  margin: 0;
  font-family: "Nova Square", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-shadow {
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5));
}

.text-color-primary {
  color: #FF6F1E;
}

h1 {
  color: white;
  font-size: calc(1.375rem + 2.5vw);
}

h2 {
  color: #2A9D8F;
  font-size: calc(1.325rem + 2vw);
}

h3 {
  color: #FF6F1E;
}

h4 {
  color: #FF6F1E;
}

h5 {
  color: white;
  margin: 0;
}

p {
  color: #2A9D8F;
}

hr {
  border: 1px solid #FF6F1E;
  margin: 0 0 10px 0;
  width: 35%;
  background-color: #FF6F1E;
}

a {
  text-decoration: none;
}

